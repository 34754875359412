import React, { useEffect, useState } from "react";
import { postProject } from "../../../../api/userRegestration/CollectionForm";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function PostProjectMain() {
  //
  const [formData, setFormData] = useState({
    projectname: "",
    companyname: "",
    email: "",
    phonenumber: "",
    projectdetails: "",
  });
  //
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const ProjectData = {
        projectName: formData.projectname,
        companyName: formData.companyname,
        details: formData.projectdetails,
        mediaUrl: "https://images.unsplash.com/photo-1620325867502-221cfb5faa5f?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvamVjdHxlbnwwfHwwfHx8MA%3D%3D",
        mail: formData.email,
        mobileNumber: formData.phonenumber,
        budget: "7OMR",
        mediaType: "MEDIA",
      };


      const Projectdatas = await postProject(ProjectData)

      console.log("post:", Projectdatas);
    } catch (error) {

  console.error("Error in project:", error.message);



    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="  bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem]">
              {/* 1 */}
              <div className="form-input flex flex-col  w-[47%]">
                <h1 className="font-all font-semibold text-center">
                  Post Project
                </h1>
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Project Name
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="projectname"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.projectname}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/* 2  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="companyname"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.companyname}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/*  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Email address
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="email"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/*  */}
                {/* 5  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Phone Number
                  </label>
                  <input
                    type="number"
                    placeholder="Write here..."
                    name="phonenumber"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.phonenumber}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/*  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                  <label
                    htmlFor="input"
                    className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Project Details
                  </label>
                  <textarea
                    placeholder="Write here..."
                    name="projectdetails"
                    rows="7"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.projectdetails}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                {/*  */}
              </div>

              {/* 2 */}

              <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
                {/*  */}

                <div className=" rounded-md h-[11rem] w-[22rem] border-[1.5px] border-dashed border-black ">
                  <div className="button">
                    <AiOutlinePlusCircle className="text-[33px] relative top-[16px] lg:mt-[3rem] mx-auto " />
                    <h5 className="text-center font-all mt-4">
                      Add Product photos
                    </h5>
                  </div>
                </div>
                <p className="text-[#738482]">Accepted Types : jpeg,jpg,png</p>

                {/*  */}

                {/* buttin action */}

                <div class="button-action flex flex-col items-center mt-[3.5rem]">
                  <button class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                    Save Changes{" "}
                  </button>

                  <Link to="/">
                    <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                      Go Back
                    </button>
                  </Link>
                </div>
              </div>
              {/*  */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
