import React, { useState } from "react";
import { MdAddPhotoAlternate } from "react-icons/md";
import { createPost } from "../../../api/userRegestration/CollectionForm";
export default function CreatePost() {
  const [formData, setFormData] = useState({
    postTitle: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //
    try {
      const postData = {
        postTitle: formData.postTitle,
        mediaUrl:
          "https://images.unsplash.com/photo-1593642633279-1796119d5482?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZGVsbHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
        details: formData.description,
        amount: "1",
        mediaType: "MEDIA",
      };


const Post = await createPost(postData)
console.log("post:", Post);
    } catch (error) {
      console.error("Error in post:", error.message);

      
    }
  };

  return (
    <div className="bg-layout drop-shadow-md lg:w-[152vh] lg:h-[77vh] lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd">
      <div className="fade-in-left flex justify-center">
        <form onSubmit={handleSubmit}>
          <div className="form">
            <h1 className="text-center font-all font-extrabold text-[23px] text-color lg:relative mt-2 mb-3">
              Create Post
            </h1>
            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
              <label
                htmlFor="postTitle"
                className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Post Title
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="postTitle"
                value={formData.postTitle}
                onChange={handleInputChange}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>

            <div className="cooltextarea flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-4">
              <label
                htmlFor="description"
                className="text text-sm text-black w-max rounded-md bg-white relative top-2.5 ml-2 px-1 w-fit-content"
              >
                Description
              </label>
              <textarea
                placeholder="Write here..."
                name="description"
                rows="12"
                value={formData.description}
                onChange={handleInputChange}
                className="textarea px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center">
              <button className="flex items-center px-4 py-2 bg-white text-black shadow-md drop-shadow-md border border-1 rounded-md m-2">
                <MdAddPhotoAlternate />
                Photo/Video
              </button>
              <button className="flex items-center px-4 py-2 bg-white text-black shadow-md drop-shadow-md border border-1 rounded-md m-2">
                <MdAddPhotoAlternate />
                Camera
              </button>
            </div>
            <button
              type="submit"
              className="mt-6 bg-[#CB2229] text-white rounded-full font-all p-[6px] w-[18rem] transform hover:scale-105 duration-500 ease-in-out relative lg:left-[7rem]"
            >
              Post
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
