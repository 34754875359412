import React, { useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";

import "react-phone-input-2/lib/style.css";

export default function VerifyPhone() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const handleMobileNumberChange = (e) => {
    if (e && e.target) {
      setMobileNumber(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://inobackend-production.up.railway.app/api/v1/user/register",
        {
          mobileNumber: `+${mobileNumber}`,
        }
      );

      console.log(response + mobileNumber);
      setResponseMessage(response.data.responseMessage);

      const url = `/auth/verifyphone/${mobileNumber}`;
      navigate(url);
    } catch (error) {
      console.error("Error registering user:", error);
      setResponseMessage("An error occurred while registering the user.");
    }
  };

  return (
    <>
      <div className="absolute top-0">
        <div
          class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
          role="alert"
        >
          {responseMessage && <p>{responseMessage}</p>}
        </div>
      </div>

      <div className="xs:min-h-screen ">
        <div className=" lg:hidden  --logo flex justify-center xs:mt-[3rem]">
          <img
            src={Logo}
            alt=""
            className="lg:w-[134px] xs:h-[49px] bounce-in-top  "
          />
        </div>
        <div className="flex flex-row justify-start  ">
          <div className="xs:hidden item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className="item-2 bg-[#cb22290f] xs:bg-white w-[50%] flex justify-center xs:w-[100%]">
            <div className="bg-white xs:bg-[#cb22290f] shdaow-md w-[88%] rounded-md  mt-[9rem] xs:mt-8 h-[513px] drop-c fade-in-right ">
              <h1 className="text-center font-bold text-[22px] mb-[6rem] relative top-[33px] xs:font-all ">
                Verify your phone number
              </h1>
              <div className="bg-white xs:bg-[#fcf2f2] w-auto  sm:w-full md:w-4/5 md:mx-auto lg:w-2/5 lg:mx-auto">
                {/* header section */}
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-center"
                >
                  {" "}
                  <label className="">
                    {/* Phone Number */}
                    <input
                      country={"us"}
                      type="text"
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                      className="shadow-md p-3 cursor-pointer"
                    />
                  </label>
                  <button
                    type="submit"
                    className="lg:mt-[11rem] xs:mt-[6rem] flex justify-center items-center mx-auto m-auto bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out "
                  >
                    Verify
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// navigate('/');
//
