import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoVerified } from "react-icons/go";
function CommunityPost() {
  const movies = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1516387938699-a93567ec168e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bGFwdG9wfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
      altText: "buyer name 2 1",
      title: "Buyer name 1",
      description: "Click the button to watch on Jetflix app.",
    },
    {
      imageSrc:
        "https://media.istockphoto.com/id/1425235236/photo/side-view-of-youthful-african-american-schoolboy-working-in-front-of-laptop.webp?b=1&s=170667a&w=0&k=20&c=kFD3SJkeFa21orHCMEkF1RUcU5VHstLUKKBvx2Xfb4Q=",
      altText: "Movie 2",
      title: "Service Name 2",
      description: "Click the button to watch on Jetflix app.",
    },
    {
      imageSrc:
        "https://media.istockphoto.com/id/1483977253/photo/stone-carving-of-goddess-nike.webp?b=1&s=170667a&w=0&k=20&c=25gzmSDINqSg1aJ-ijxH5be095p-Z6reYmwcPkxanJk=",
      altText: "buyer name 2 1",
      title: "Buyer name 1",
      description: "Click the button to watch on Jetflix app.",
    },
    // Add more movie objects as needed
  ];

  const [Post, setPost] = useState([]);
  const accessToken = localStorage.getItem("token");
// 

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://inobackend-production.up.railway.app/api/v1/user/getAllPostList",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );
      const data = response.data;
      setPost(data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("mnssnfkqfnelgk");
  fetchData();
}, [accessToken]);

// 
return (
    <>
      <div
        className="flex items-center justify-center mt-10 rounded w-[93%]
          mx-auto "
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className="flex flex-col gap-4 mb-8">
          <h1 className="font-all font-bold text-start -auto text-[21px] lg:mt-[27px] mb-6">
            All Community Posts
          </h1>
          <section
  id="Projects"
  className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
>
  {Post.map((item, index) => (
    <div
      key={index}
      className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
    >
      <a href="#">
        <img
          src={item.mediaUrl}
          alt="img not found "
          className="h-80 w-72 object-cover rounded-t-xl"
        />
        <div className="px-4 py-3 w-72">
          <span className="text-gray-400 mr-3 uppercase text-xs">
            post tile
          </span>
          <p className="text-lg font-bold text-black truncate block capitalize">
            {item.postTitle}
          </p>
          <span className="text-gray-400 mr-3  mt-3 uppercase text-xs">
            post description
          </span>
          <div className="flex items-center">
            <p className="text-lg font-semibold text-black cursor-auto my-2">
            {item.details}
            </p>
            
          </div>
        </div>
      </a>
    </div>
  ))}
</section>

          <h1 className=" mt-2 cursor-pointer underline-offset-4 text-center font-all    hover:text-blue-600 w-[12%] mx-auto">
            See more
          </h1>
        </div>
      </div>
    </>
  );
}

export default CommunityPost;
